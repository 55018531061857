<template>
  <j-actions :type="type">
    <a-menu>
      <a-menu-item
        @click="onShop"
        v-if="$auth.granted(acl.shops.admin)">
        <a-icon type="shop" class="text-secondary" />
        {{ $t('msg.account.action.shop') }}
      </a-menu-item>
      <a-menu-item
        @click="onEdit"
        v-if="$auth.granted(acl.accounts.update)">
        <a-icon type="form" class="text-secondary" />
        {{ $t('msg.account.action.edit') }}
      </a-menu-item>
      <a-menu-item
        @click="onRate"
        v-if="$auth.granted(acl.rates.update)">
        <a-icon type="dollar" class="text-secondary" />
        {{ $t('msg.account.action.rate') }}
      </a-menu-item>
      <a-menu-item
        @click="onStore"
        v-if="$auth.granted(acl.stores.update)">
        <a-icon type="environment" class="text-secondary" />
        {{ $t('msg.account.action.store') }}
      </a-menu-item>
      <a-menu-item
        @click="onTemplate"
        v-if="$auth.granted(acl.templates.update)">
        <a-icon type="link" class="text-secondary" />
        {{ $t('msg.account.action.template') }}
      </a-menu-item>
      <a-menu-item
        @click="onToken"
        v-if="$auth.granted(acl.tokens.update)">
        <a-icon type="key" class="text-secondary" />
        {{ $t('msg.account.action.token') }}
      </a-menu-item>
      <a-menu-item
        @click="onUser"
        v-if="$auth.granted(acl.tokens.update)">
        <a-icon type="user" class="text-secondary" />
        {{ $t('msg.account.action.user') }}
      </a-menu-item>
      <a-menu-item
        @click="onTroubleshoot"
        v-if="$auth.granted(acl.accounts.update)">
        <a-icon type="tool" class="text-secondary" />
        {{ $t('msg.account.action.troubleshoot') }}
      </a-menu-item>
      <!-- <a-menu-item
        @click="onMock"
        v-if="$auth.granted(acl.accounts.mock)">
        <a-icon type="bug" class="text-secondary" />
        {{ $t('msg.account.action.mock') }}
      </a-menu-item> -->
      <a-menu-item
        @click="onActivate"
        v-if="$auth.granted(acl.accounts.update) && item.activation !== 'ACTIVE'">
        <a-icon type="check-circle" class="text-secondary" />
        {{ $t('msg.account.action.activate') }}
      </a-menu-item>
      <a-menu-item
        class="text-danger"
        @click="onDeactivate"
        v-if="$auth.granted(acl.accounts.update) && item.activation === 'ACTIVE'">
        <a-icon type="close-circle" class="text-danger" />
        {{ $t('msg.account.action.deactivate') }}
      </a-menu-item>
    </a-menu>
  </j-actions>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { Modal } from 'ant-design-vue'
import JActions from '@/views/shared/base/actions'
export default {
  props: [
    'item',
    'type',
  ],
  components: {
    JActions,
  },
  data: function () {
    return {
      acl,
    }
  },
  methods: {
    onEdit() {
      this.$router.push(`/accounts/${this.item.id}/update`)
    },
    onRate() {
      this.$router.push(`/accounts/${this.item.id}/rates`)
    },
    onTemplate() {
      this.$router.push(`/accounts/${this.item.id}/templates`)
    },
    onToken() {
      this.$router.push(`/accounts/${this.item.id}/tokens`)
    },
    onStore() {
      this.$router.push(`/accounts/${this.item.id}/stores`)
    },
    onShop() {
      this.$router.push(`/accounts/${this.item.id}/shops`)
    },
    onTroubleshoot() {
      this.$router.push(`/accounts/${this.item.id}/troubleshoot`)
    },
    onMock() {
      this.$store.commit('session/SET_ACCOUNT', this.item)
    },
    onActivate() {
      lms.account.activate(this.item.id)
        .then(() => this.$message.success(this.$t('msg.account.activated')))
    },
    onUser() {
      Modal.confirm({
        title: this.$t('msg.account.action.user'),
        content: h => <a-input vModel={this.email} placeholder='mail@example.com'/>,
        okText: this.$t('confirm'),
        cancelText: this.$t('cancel'),
        okType: 'danger',
        onOk: () => {
          lms.account.createUser(this.item.id, this.email)
            .then(() => this.$message.success(this.$t('msg.account.granted')))
        },
      })
    },
  },
}
</script>

<template>
  <a-dropdown
    :trigger="['click']">
    <template #overlay>
      <slot></slot>
    </template>
    <slot name="button">
      <a-button
        icon="ellipsis"
        @click="e => e.stopPropagation()"
        v-if="type === 'icon'" />
      <a-button
        size="large"
        type="primary"
        style="margin-left: 8px"
        v-else>
        {{ $t('actions') }}<a-icon type="down" />
      </a-button>
    </slot>
  </a-dropdown>
</template>
<script>
export default {
  props: {
    type: String,
  },
}
</script>

export const linkToGoogleMaps = (address) => {
  return address.lat && address.lng
    ? `https://www.google.com/maps/dir/?api=1&destination=${address.lat},${address.lng}&travelmode=driving`
    : `https://www.google.com/maps/dir/?api=1&destination=${address.street}&travelmode=driving`
}

export const linkToWhatsApp = (phone, message) => {
  return message
    ? `https://wa.me/${phone}?text=${message}`
    : `https://wa.me/${phone}`
}

<template>
  <a v-if="whatsapp" :href="whatsapp" target="_blank">
    {{ pretty }}
  </a>
  <span v-else style="cursor: not-allowed">
    {{ pretty }}
  </span>
</template>

<script>
import { linkToWhatsApp } from '@/utils/links'
import parse from 'libphonenumber-js'
export default {
  name: 'LabelPhone',
  props: [
    'value',
  ],
  computed: {
    parsed() {
      return parse(this.value)
    },
    pretty() {
      return (this.parsed) ? this.parsed.formatNational() : this.value
    },
    whatsapp() {
      return this.parsed ? linkToWhatsApp(this.parsed.number) : null
    },
  },
}
</script>

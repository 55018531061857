<template>
  <j-abm
    name="account"
    avatar="apple"
    :suppliers="suppliers"
    :features="{
      detail: false,
      search: true,
      create: true,
      update: true,
      delete: false,
    }">
    <!-- Item -->
    <template #row="{item}">
      <div>
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6">
              <h5>
                <!-- Activation -->
                <a-tooltip
                  :title="$t(`enum.activation.${item.activation}`)"
                  placement="left">
                  <a-badge :status="activation[item.activation].color"/>
                </a-tooltip>
                <!-- Name -->
                {{ item.contact.name }}
              </h5>
              <!-- Account ID -->
              <j-label icon="number">
                {{ item.id }}
              </j-label>
            </div>
            <div class="col-12 col-md-6">
              <!-- Email -->
              <j-label icon="mail">
                <j-email :value="item.contact.email" />
              </j-label>
              <!-- Phone -->
              <j-label icon="phone">
                <j-phone :value="item.contact.mobile || item.contact.phone" />
              </j-label>
              <!-- Identification -->
              <j-label icon="idcard">
                {{ item.contact.identification }}
              </j-label>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- Actions -->
    <template #actions="{item}">
      <j-actions type="icon" :item="item" />
    </template>
  </j-abm>
</template>

<script>
import lms from '@/api/lms'
import acl from '@/auth/acl'
import { activation } from '@/utils/styles'
import JAbm from '@/views/shared/abm/search'
import JLabel from '@/views/shared/labels/text'
import JEmail from '@/views/shared/labels/email'
import JPhone from '@/views/shared/labels/phone'
import JActions from './actions'
export default {
  components: {
    JAbm,
    JLabel,
    JEmail,
    JPhone,
    JActions,
  },
  data: function () {
    return {
      acl,
      activation,
    }
  },
  computed: {
    suppliers() {
      return {
        search: query => lms.account.search(query),
        fetchAll: page => lms.account.fetchAll(page),
      }
    },
  },
}
</script>
